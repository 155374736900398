import React from 'react';
import { Box, Text, Button } from 'grommet';
import moment from 'moment';

import EditIconAtom from 'atoms/EditIcon';
import DeleteIconAtom from 'atoms/DeleteIcon';
import EditTableCell from 'pages/components/EditTableCell';

export const getEmployeeColumns = (handleSubmit, openRemoveEmpModal, policyType) => {
  return [
    {
      Header: <Box pad={{ left: 'medium' }}>NAME</Box>,
      width: 230,
      id: 'name',
      accessor: 'name',
      sortable: false,
      resizable: false,
      Cell: row => {
        return (
          <Box pad={{ left: 'medium' }}>
            <Text>{row.original.name}</Text>
          </Box>
        );
      },
    },
    {
      Header: 'TITLE',
      width: 230,
      id: 'title',
      accessor: 'title',
      sortable: true,
      resizable: false,
      Cell: row => {
        return (
          <Box>
            <Text>{row.original.title}</Text>
          </Box>
        );
      },
    },
    {
      Header: 'USERNAME',
      id: 'username',
      accessor: 'username',
      width: 228,
      sortable: false,
      resizable: false,
      Cell: row => {
        return (
          <Box>
            <Text>{row.original.username}</Text>
          </Box>
        );
      },
    },
    {
      Header: 'HIREDATE',
      accessor: 'hire_date',
      id: 'hire_date',
      width: 210,
      resizable: false,
      Cell: row => {
        return (
          <Box>
            <Text>{row.original.hire_date_display}</Text>
          </Box>
        );
      },
    },
    ...(policyType !== 'PAID_HOLIDAYS'
      ? [
          {
            Header: 'BALANCE',
            accessor: 'balance',
            id: 'balance',
            width: 220,
            resizable: false,
            Cell: row => {
              return (
                <Box alignSelf="center">
                  <EditTableCell
                    type="number"
                    tabIndex={row.viewIndex + 1}
                    value={row.original.balance}
                    onSubmit={value => {
                      handleSubmit(value, row.original, row.index);
                    }}
                  />
                </Box>
              );
            },
          },
        ]
      : []),
    {
      Header: 'ACTION',
      width: 80,
      resizable: false,
      sortable: false,
      Cell: row => {
        return (
          <Button alignSelf="center" onClick={() => openRemoveEmpModal(row.original.employee_id)} title="Delete Policy">
            <DeleteIconAtom height="15" width="15" />
          </Button>
        );
      },
    },
  ];
};

export const getHolidayColumns = (setValues, dateFormatDisplay, setOldValues, toggleDeleteLayer, disabled) => {
  return [
    {
      Header: 'ACTION',
      resizable: false,
      sortable: false,
      Cell: row => {
        return (
          <Box align="center" direction="row" gap="small">
            <Button
              disabled={disabled}
              alignSelf="center"
              onClick={() => {
                setValues({
                  ...(row?.original || {}),
                  date: moment(row?.original?.date).format(dateFormatDisplay),
                  edit: true,
                });
                setOldValues({
                  ...(row?.original || {}),
                  date: moment(row?.original?.date).format(dateFormatDisplay),
                });
              }}
              title="Edit Holiday"
            >
              <EditIconAtom height="15" width="15" />
            </Button>
            <Button
              disabled={disabled}
              alignSelf="center"
              onClick={() => toggleDeleteLayer({ show: true, data: row.original })}
              title="Delete Holiday"
            >
              <DeleteIconAtom height="15" width="15" />
            </Button>
          </Box>
        );
      },
    },
    {
      Header: 'NAME',
      id: 'name',
      accessor: 'name',
      sortable: false,
      resizable: false,
      Cell: row => {
        console.log('row', row);
        return (
          <Box>
            <Text>{row.original.name}</Text>
          </Box>
        );
      },
    },
    {
      Header: 'DATE',
      accessor: 'date',
      id: 'date',
      resizable: false,
      Cell: row => {
        return (
          <Box>
            <Text>{row.original.date}</Text>
          </Box>
        );
      },
    },
    {
      Header: 'HOURS',
      accessor: 'number_of_hours',
      id: 'number_of_hours',
      resizable: false,
      Cell: row => {
        return (
          <Box>
            <Text>{row.original.number_of_hours}</Text>
          </Box>
        );
      },
    },
  ];
};
